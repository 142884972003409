export const EVERNOW_PHONE_NUMBER = "5103692094";
export const EVERNOW_ADDRESS1 = "400G Treat Ave";
export const EVERNOW_CITY = "San Francisco";
export const EVERNOW_STATE = "CA";
export const EVERNOW_ZIP = "94110";

export const GRAPHQL_ENDPOINT = "/graphql";

export enum PlanCancellationReason {
  Insurance = "CHEAPER_WITH_INSURANCE",
  Mammogram = "MAMMOGRAM",
  MedicationDelivery = "MEDICATION_DELIVERY",
  NoImprovement = "NO_IMPROVEMENT",
  NotEnoughMedicalSupport = "NOT_ENOUGH_MEDICAL_SUPPORT",
  NotQualifiedForDesiredTreatment = "NOT_QUALIFIED_FOR_DESIRED_TREATMENT",
  Other = "OTHER",
  PersonalPhysician = "PERSONAL_PHYSICIAN",
  PriceTooHigh = "PRICE_TOO_HIGH",
  IntolerableSideEffects = "INTOLERABLE_SIDE_EFFECTS",
  StoppedResponding = "STOPPED_RESPONDING",
  Surplus = "SURPLUS",
  BillingFailure = "BILLING_FAILURE",
}

export const PlanCancellationOptions: Array<{ value: PlanCancellationReason; label: string }> = [
  // Organized alphabetically by label:
  { value: PlanCancellationReason.BillingFailure, label: "Billing failure" },
  { value: PlanCancellationReason.Insurance, label: "Insurance" },
  { value: PlanCancellationReason.Mammogram, label: "Mammogram" },
  { value: PlanCancellationReason.MedicationDelivery, label: "Medication delivery" },
  { value: PlanCancellationReason.NoImprovement, label: "No improvement" },
  { value: PlanCancellationReason.NotEnoughMedicalSupport, label: "Not enough support" },
  {
    value: PlanCancellationReason.NotQualifiedForDesiredTreatment,
    label: "Not qualified for desired treatment",
  },
  { value: PlanCancellationReason.Other, label: "Other (note details)" },
  { value: PlanCancellationReason.PersonalPhysician, label: "Personal physician" },
  { value: PlanCancellationReason.PriceTooHigh, label: "Price too high" },
  { value: PlanCancellationReason.IntolerableSideEffects, label: "Side effects" },
  { value: PlanCancellationReason.StoppedResponding, label: "Stopped responding" },
  { value: PlanCancellationReason.Surplus, label: "Surplus" },
];

export enum TicketUserAction {
  AssignTicket = "ASSIGN_TICKET",
  AddTicketNote = "ADD_TICKET_NOTE",
  QualifyPlan = "QUALIFY_PLAN",
  CreateMessage = "CREATE_MESSAGE",
  DisqualifyPlan = "DISQUALIFY_PLAN",
  ProposeTreatment = "PROPOSE_TREATMENT",
  CreateTreatment = "CREATE_TREATMENT",
  ViewTicket = "VIEW_TICKET",
  CreateRenwalTicket = "CREATE_RENEWAL_TICKET",
  SendToSupport = "SEND_TO_SUPPORT",
  CloseTicket = "CLOSE_TICKET",
}

export enum TicketType {
  Intake = "INTAKE",
  Inquiry = "INQUIRY",
  Renewal = "RENEWAL",
}

export enum TicketState {
  Open = "OPEN",
  Closed = "CLOSED",
}

export enum Sex {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}
/**
 * This type must be kept in-sync with
 * www/src/components/intake_wizard/data.ts:GenderIdentity
 */
export enum GenderIdentity {
  Female = "FEMALE",
  Male = "MALE",
  TransFemale = "TRANS_FEMALE",
  TransMale = "TRANS_MALE",
  Queer = "QUEER",
  DifferentIdentity = "DIFFERENT_IDENTITY",
}

export enum UserAuthenticatorType {
  GoogleOtp = "GOOGLE_OTP",
}

export enum PlanState {
  New = "NEW",
  IntakePaid = "INTAKE_PAID",
  Qualified = "QUALIFIED",
  Cancelled = "CANCELLED",
  Active = "ACTIVE",
  PaymentFailure = "PAYMENT_FAILURE",
}

export const TreatmentStates = [
  "NEW",
  "PENDING",
  "APPROVED",
  "REJECTED",
  "SCRIPT_ISSUED",
  "SCRIPT_ISSUE_ERRORED",
  "ORDERED",
  "FILLED",
  "SHIPPED",
  "DELIVERED",
  "ERROR",
  "CANCELLED",
] as const;
export type TreatmentState = (typeof TreatmentStates)[number];

export enum ObjectType {
  User = "USER",
  UserAuthenticator = "USER_AUTHENTICATOR",
  Patient = "PATIENT",
  PatientClinicalNote = "PATIENT_CLINICAL_NOTE",
  Checkin = "CHECKIN",
  CheckinMedication = "CHECKIN_MEDICATION",
  CheckinSymptom = "CHECKIN_SYMPTOM",
  MedicalEvent = "MEDICAL_EVENT",
  PatientAddress = "PATIENT_ADDRESS",
  Intake = "INTAKE",
  IntakeFlag = "INTAKE_FLAG",
  Upload = "UPLOAD",
  Employee = "EMPLOYEE",
  Ticket = "TICKET",
  TicketAction = "TICKET_ACTION",
  TicketNote = "TICKET_NOTE",
  Searchable = "SEARCHABLE",
  TruepillPrescription = "TRUEPILL_PRESCRIPTION",
  TruepillPrescriptionEvent = "TRUEPILL_PRESCRIPTION_EVENT",
  PatientConsent = "PATIENT_CONSENT",
  PubsubAck = "PUBSUB_ACK",
  Plan = "PLAN",
  PlanStripeLog = "PLAN_STRIPE_LOG",
  TicketCharge = "TICKET_CHARGE",
  Treatment = "TREATMENT",
  TreatmentShipment = "TREATMENT_SHIPMENT",
  TreatmentMedication = "TREATMENT_MEDICATION",
  Medication = "MEDICATION",
  StripeInvoice = "STRIPE_INVOICE",
  StripeCharge = "STRIPE_CHARGE",
  Transaction = "TRANSACTION",
  Message = "MESSAGE",
  MessageView = "MESSAGE_VIEW",
  EmployeeInvite = "EMPLOYEE_INVITE",
  EmployeeLicense = "EMPLOYEE_LICENSE",
  StripeAuthorization = "STRIPE_AUTHORIZATION",
  EmailCollection = "EMAIL_COLLECTION",
}

export enum MedicalEventCategory {
  Hospitalization = "HOSPITALIZATION",
  Surgery = "SURGERY",
  Issue = "ISSUE",
  Screening = "SCREENING",
}

export enum MedicalEventType {
  Cancer = "CANCER",
  Stroke = "STROKE",
  Clot = "CLOT",
  LiverDisease = "LIVER_DISEASE",
  CoronaryHeartDisease = "CORONARY_HEART_DISEASE",
  Depression = "DEPRESSION",
  UnexplainedBleeding = "UNEXPLAINED_BLEEDING",
  Hysterectomy = "HYSTERECTOMY",
  Mammogram = "MAMMOGRAM",
  NoMammogram = "NO_MAMMOGRAM",
  BreastCancer = "BREAST_CANCER",
  UterineEndometrialCancer = "UTERINE_OR_ENDOMETRIAL_CANCER",
  OvarianCancer = "OVARIAN_CANCER",
  GallbladderDisease = "GALLBLADDER_DISEASE",
  Lupus = "LUPUS",
  HeartAttack = "HEART_ATTACK",
  BloodClot = "CLOT",
  Oophorectomy = "OOPHORECTOMY",
  InheritedThrombophilia = "INHERITED_THROMBOPHILIA",
  AtrialFibrillation = "ATRIAL_FIBRILLATION",
  MetastaticMelanoma = "METASTATIC_MELANOMA",
  TypeOneDiabetes = "TYPE_ONE_DIABETES",
  TypeTwoDiabetes = "TYPE_TWO_DIABETES",
  WheelchairOrLimitedMobility = "LIMITED_MOBILITY",
  Other = "OTHER",
}

export enum FilterOrderDirection {
  Desc = "DESC",
  Asc = "ASC",
}

export enum PricePlanTier {
  Premium = "PREMIUM",
  Patch = "PATCH",
  Transd = "TRANSD",
  Membership = "MEMBERSHIP",
}

export const PricePlanNames: Record<PricePlanTier, string> = {
  PATCH: "Evernow Patch",
  PREMIUM: "Evernow Premium",
  TRANSD: "Transdermal Cream",
  MEMBERSHIP: "Evernow Membership",
};

export enum PatientProtocolTag {
  // Includes paroxetine and other non hormonal treatment, possibly supplements
  NonHormonal = "NON_HORMONAL",
  // HRT Pill with additional Progesterone
  PillProgesterone = "PILL_PROGESTERONE",
  // HRT Pill without Progesterone
  Pill = "PILL",
  // Hormone-based vaginal cream
  Cream = "CREAM",
  CreamSsri = "CREAM_SSRI", //TODO: Remove once we excise all references from the db
  // HRT Patch with additional Progesterone
  PatchProgesterone = "PATCH_PROGESTERONE",
  // Transdermal estrogen
  Transd = "TRANSD",
  // Transdermal estrogen + progesterone
  TransdProgesterone = "TRANSD_PROGESTERONE",
  // Combipatch Transdermal
  TransdCombination = "TRANSD_COMBINATION",
  // Vaginal estradiol tablets
  VaginalEstradiol = "VAGINAL_ESTRADIOL",
  // HRT Patch without Progesterone
  Patch = "PATCH",
  // Oral minoxidil
  OralMinoxidil = "ORAL_MINOXIDIL",
  // Topical minoxidil + finasteride compunded formula
  TopicalMinoxidilFinasteride = "TOPICAL_MINOXIDIL_FINASTERIDE",
  // Veozah
  Veozah = "VEOZAH",
  GLP1 = "GLP1",
  Metformin = "METFORMIN",
  Contrave = "CONTRAVE",
  Derm = "DERM",
  None = "NONE",
}

export enum IdentityVerificationState {
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
  Error = "ERROR",
}

export enum IdentityErrorType {
  Invalid = "INVALID",
  BadImage = "BAD_IMAGE",
  NameMismatch = "NAME_MISMATCH",
  DobMismatch = "DOB_MISMATCH",
}

export const IdentityErrorLabels = {
  [IdentityErrorType.Invalid]: "Invalid image",
  [IdentityErrorType.BadImage]: "Bad image",
  [IdentityErrorType.NameMismatch]: "Name mismatch",
  [IdentityErrorType.DobMismatch]: "Date of Birth mismatch",
};

export enum AutofillMessageCategory {
  Message = "MESSAGE",
  Prescription = "PRESCRIPTION",
  TreatmentIntroduction = "TREATMENT_INTRODUCTION",
  TreatmentBenefits = "TREATMENT_BENEFITS",
  TreatmentTips = "TREATMENT_TIPS",
  TreatmentCheckins = "TREATMENT_CHECKINS",
  TreatmentExpectations = "TREATMENT_EXPECTATIONS",
}

export type AutofillMessage = {
  id: string;
  label: string | null;
  content: string | null;
  category: AutofillMessageCategory | null;
  medicationCategory: MedicationCategory | null;
};

export const MedicationCategories = [
  "HRT_PILL",
  "HRT_PATCH",
  "HRT_PILL_COMBINATION",
  "HRT_TRANSDERMAL",
  "HRT_TRANSDERMAL_COMBINATION",
  "PROGESTERONE",
  "CREAM",
  "SSRI",
  "SUPPLEMENT",
  "VAGINAL_ESTRADIOL",
  "TOPICAL_MINOXIDIL_FINASTERIDE",
  "VEOZAH",
  "GLP1",
  "METFORMIN",
  "CONTRAVE",
  "DERM",
] as const;
export type MedicationCategory = (typeof MedicationCategories)[number];

export const MedicationCategory: Record<string, MedicationCategory> = {
  HrtPill: "HRT_PILL",
  HrtPatch: "HRT_PATCH",
  HrtPillCombination: "HRT_PILL_COMBINATION",
  HrtTransdermal: "HRT_TRANSDERMAL",
  HrtTransdermalCombination: "HRT_TRANSDERMAL_COMBINATION",
  Progesterone: "PROGESTERONE",
  Cream: "CREAM",
  Ssri: "SSRI",
  Supplement: "SUPPLEMENT",
  VaginalEstradiol: "VAGINAL_ESTRADIOL",
  TopicalMinoxidilFinasteride: "TOPICAL_MINOXIDIL_FINASTERIDE",
  Veozah: "VEOZAH",
  GLP1: "GLP1",
  Metformin: "METFORMIN",
  Contrave: "CONTRAVE",
  Derm: "DERM",
};

export const MedicalEventTags = [
  "TREATED",
  "TREATMENT_ACTIVE",
  "CURED",
  "IN_REMISSION",
  "ABNORMAL",
  "BENIGN",
  "BILATERAL",
  "UNKNOWN",
] as const;
export type MedicalEventTag = (typeof MedicalEventTags)[number];

export const PlanBillingCycles = ["ONE_MONTH", "THREE_MONTH", "TWELVE_MONTH"] as const;
export type PlanBillingCycle = (typeof PlanBillingCycles)[number];

export const PrescriptionStates = ["PENDING", "ACTIVE", "EXPIRED", "CANCELLED"] as const;
export type PrescriptionState = (typeof PrescriptionStates)[number];

export const getPrescriptionState = ({
  approvedAt,
  cancelledAt,
  remainingRefills,
}: {
  approvedAt: string | null;
  cancelledAt: string | null;
  remainingRefills: number;
}): PrescriptionState =>
  cancelledAt ? "CANCELLED" : approvedAt ? (remainingRefills > 0 ? "ACTIVE" : "EXPIRED") : "PENDING";

export const Symptoms = [
  "HOT_FLASHES",
  "NIGHT_SWEATS",
  "SLEEP_DISRUPTION",
  "BRAIN_FOG",
  "PAINFUL_SEX",
  "DRY_VAGINA",
  "URGENT_BATHROOM_TRIPS",
  "MOOD_SWINGS",
  "UNUSUAL_PERIOD",
  "SKIN_HAIR_CHANGES",
  "INCREASED_FACIAL_HAIR_ACNE",
  "FATIGUE_LOW_ENERGY",
  "WEIGHT_CHANGES",
  "ANXIETY_DEPRESSION",
  "HEART_DISCOMFORT",
  "JOINT_MUSCULAR_DISCOMFORT",
  "LOW_LIBIDO",
] as const;

export type Symptom = (typeof Symptoms)[number];

export type SymptomEntry = {
  symptom: Symptom;
  severity: number | null;
  notes?: string | null;
};

export const PharmacyOrderStates = ["NEW", "ORDERED", "FILLED", "SHIPPED", "DELIVERED", "ERROR", "CANCELLED"] as const;
export type PharmacyOrderState = (typeof PharmacyOrderStates)[number];

export const OrderEventTypes = [
  "PharmacyOrderSuccess",
  "PharmacyOrderConfirmed",
  "PharmacyOrderFailure",
  "PharmacyWebhookPayloadFailure",
  "PharmacyOrderDelivery",
  "ShipmentRecorded",
  "ShipmentTrackingStarted",
  "ShipmentFailed",
  "FulfillmentNote",
  "ShippingUpdate",
  "ShipmentNoticeReceived",
] as const;
export type OrderEventType = (typeof OrderEventTypes)[number];

export const Carriers = ["usps", "fedex", "ups"] as const;
export type Carrier = (typeof Carriers)[number];

export const OrderStates = ["NEW", "ORDERED", "FILLED", "SHIPPED", "DELIVERED", "ERROR", "CANCELLED"] as const;
export type OrderState = (typeof OrderStates)[number];

export const TreatmentPlanStates = ["APPROVED", "PENDING_APPROVAL", "EXPIRED"] as const;
export type TreatmentPlanState = (typeof TreatmentPlanStates)[number];

export const MedicationStates = ["FILLED", "RECEIVED", "USING", "PAUSED", "STOPPED", "ERROR"] as const;
export type MedicationState = (typeof MedicationStates)[number];

export type PharmacyFulfillment = "LOCAL" | "EVERNOW";

export type FillDuration = "STANDARD" | "EXTENDED";

//This should always be the same as the constant declared in api/src/lib/medication_constants.ts. Do not change here unless first changed there.
export const DAYS_TO_PROCESS_SHIPMENT = 10;
