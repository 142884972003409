import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureFlagsStoreState, Flags } from "./types";

export const initialState: FeatureFlagsStoreState = {
  flags: {
    ehr_content_generator_preview: false,
    ehr_content_generator_experimental: false,
    ehr_documents_tab: false,
    ehr_dosespot_v2: false,
    ehr_tasklists: false,
    ehr_video_visit_soap_notes: false,
    free_vaginal_estradiol: false,
    kill_the_snoozer: false,
    video_visit_follow_ups: false,
  },
};

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFlags: (state, action: PayloadAction<Flags>) => {
      state.flags = action.payload;
    },
  },
});

export default featureFlagsSlice.reducer;
